import * as React from "react"
import { useEffect } from 'react';
import { Link, graphql } from 'gatsby'
import anime from "animejs";
import { StaticImage } from "gatsby-plugin-image"

import Seo from "../components/seo"
import Layout from "../components/layout"

import NewPostsSwiper from '../components/index/new-posts-swiper'
import MainSlideImageSwiper from '../components/index/main-slide-image-swiper'
import NewsList from '../components/index/news-list'
import SightseeingList from '../components/index/sightseeing-list'
import OnlinebarList from '../components/index/onlinebar-list'

import kvLogo from "../images/logo-white.svg"
import kvsubTitle from "../images/kv-sub-ttl.png"

const ttt = " TANOSHIGE【たのしげ】"
const ddd = "HIROSHIMA TANOSHIGEは、広島の楽しげなヒト・モノ・コトを日本、そして世界に発信しています。繋がりから新たな交流や企画を生み出していきます。ラジオの親近感とテレビのエンターテイメント、人との繋がり面白いコトを創り出す、まさに“見えるラジオ 繋がるテレビ”をお届けします。"

// markup
const IndexPage = ({ data }) => {

  useEffect(() => {
    setTimeout(function () {      
      // Create a timeline with default parameters
      let tl = anime.timeline({
        easing: 'spring',
        duration: 1250
      });

      // Add children
      tl
      .add({
        targets: '#kv-logo',
        bottom: '50%',
      })
      .add({
        targets: '.kv-img',
        opacity: 1,
      })
      .add({
        targets: '#kv-sub-ttl',
        opacity: 1,
      });

    }, 1000)
  })

  return (
    <Layout isHomePage={true} headerClass="bg-default" isLogoOneWhite={false} isLogoTwoWhite={true}>
      <Seo title={ttt} description={ddd} />
      <main>

        <section id="kv">
          <div id="kv-stage">
            <div id="kv-bottom"><StaticImage src="../images/kv-bottom.jpg" placeholder="none" loading="eager" alt="TANOSHIGE [たのしげ]" /></div>
            <div className="kv-img">
              <StaticImage src="../images/kv-full.jpg" placeholder="none" loading="eager" alt="TANOSHIGE [たのしげ]" />
            </div>
            <div className="kv-img-sp">
              <StaticImage src="../images/kv-sp.png" placeholder="none" loading="eager" alt="TANOSHIGE [たのしげ]" />
            </div>
          </div>
          <div id="kv-logo">
            <img src={kvLogo} alt="" />
          </div>
          <div id="kv-sub-ttl">
            <img src={kvsubTitle} alt="" />
          </div>
          
        </section>
        <NewPostsSwiper />
        <section id="top-about">
          <h2><StaticImage src="../images/ttl-content-1st@2x.png" alt="見えるラジオ繋がるテレビ" /></h2>
          <div id="toroku-content"><StaticImage src="../images/ttl-content-2nd@2x.png" alt="貢藤十六" /></div>
          <p className="inner top-about-note">
            HIROSHIMA TANOSHIGEは、広島の楽しげなヒト・モノ・コトを日本、そして世界に発信しています。<span className="pc-br"></span>
広島は世界的に有名な都市ですが、まだまだ知られていない魅力にたくさん溢れています。<span className="pc-br"></span>
今の広島の楽しげなテーマを語りあうヒロシマオンライン酒場TANOSHIGEは<span className="pc-br"></span>
日本初のZOOM DJ（？）が話題の人とオンラインでトークを繰り広げる参加型コンテンツ。<span className="pc-br"></span>
そして、ここでの繋がりが新たな交流や企画を生み出していきます。<span className="pc-br"></span>
ラジオの親近感とテレビのエンターテイメント、人との繋がり面白いコトを創り出す、<span className="pc-br"></span>
まさに“見えるラジオ 繋がるテレビ”をお届けします。
            </p>
        </section>

        <section id="sightseeing">
          <h2><StaticImage src="../images/ttl-sightseeing@2x.png" alt="SIGHTSEEING" /></h2>
          <MainSlideImageSwiper />
        </section>

        <SightseeingList />

        <OnlinebarList />

        <NewsList />

        <section id="kudo">
          <h2><StaticImage src="../images/ttl-toroku@2x.png" alt="貢藤十六" /></h2>
          <div className="kudo-content inner">
            <div className="kudo-img">
              <StaticImage src="../images/toroku-kudo.png" alt="TOROKU KUDO" />
            </div>
            <div className="kudo-note">
              <h3>番組MC 貢藤十六</h3>
              <p>
                身長:178 出身地：大阪市 1974年生まれ かに座・A型<br />
                趣味：ツーリング、ドライブ、ギター。<br />
                オーストラリアでの生活、サラリーマンを経て2003年よりラジオ局でDJ、スポーツイベントを中心にMCの仕事をスタート。TV番組・YouTube動画・CMなどの声も多数。また近年、舞台俳優も経験し、幅広く活動を展開。
              </p>
            </div>
          </div>
          <Link className="link-btn" to="/toroku"><span>WORKSはこちら</span></Link>
        </section>
      </main>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    allWpNews(sort: { fields: [date], order: DESC }) {
      totalCount
      edges {
        node {
          id
          uri
          title
          slug
          date(formatString: "Do MMMM")
        }
      }
    }
  }
`

export default IndexPage
